// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.contact-form-button-custom{
    background: #8b2b7b !important;
    border: 2px solid #8b2b7b !important;

    &:hover{
        background: rgba(0,0,0,0) !important;
        color: #8b2b7b !important;
    }
}

.title.theme-gradient {
    background: linear-gradient(145deg, rgb(252, 213, 53) 0%, rgb(209, 18, 104) 100%);
    -webkit-background-clip: text;
    background-clip: text;
}